<script lang="ts" setup>
withDefaults(
  defineProps<{
    showAlertTitle: boolean
    alertTitle: string
    titleClasses: string
    variant: 'warning' | 'danger' | 'secondary' | 'black' | 'tiger' | 'danger-dark'
  }>(),
  {
    alertTitle: '',
    variant: 'danger',
  },
);
</script>

<template>
  <v-alert class="d-flex" :classes="titleClasses" role="alert" :variant="variant">
    <div v-if="showAlertTitle && !!alertTitle" class="alert-title fw-semi mb-4" data-cy="alert-title">
      {{ alertTitle }}
    </div>
    <div>
      <slot />
    </div>
  </v-alert>
</template>

<style lang="scss" scoped>
[data-bs-theme='light'] {
  .alert-title {
    color: var(md-dark);
  }
}
</style>
