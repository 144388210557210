<script lang="ts" setup>
defineOptions({
  name: 'DashboardWidgetUpdater',
});

defineProps<Props>();

interface Props {
  showUpdaterWidget: boolean
  itemsToUpdateOnSitesCount: number
}
</script>

<template>
  <div
    v-if="showUpdaterWidget && itemsToUpdateOnSitesCount > 0"
    id="sites-update-widget"
    class="sites-widget d-flex justify-content-between text-bg-primary-light rounded-xs mb-8"
  >
    <span id="updates-title" class="updates-title align-self-center fw-medium mb-0 px-24 py-8 text-xs">
      {{ $t('manager.availableUpdates', { total: itemsToUpdateOnSitesCount }, itemsToUpdateOnSitesCount) }}
    </span>

    <router-link
      :to="{ name: 'manager.update.index' }"
      class="btn btn-primary btn-sm btn-go-to-updater fw-semi border-0"
    >
      {{ $t('dashboard.goToUpdates') }}
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.sites-widget {
  min-height: 2.5rem;
}

.btn-go-to-updater {
  border-radius: 0 0.25rem 0.25rem 0;
}
</style>
