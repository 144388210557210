<script lang="ts" setup>
defineOptions({
  name: 'TheSiteMenu',
});

defineProps<{
  site: string
}>();
</script>

<template>
  <div id="the-site-menu">
    <TheSiteTeamMenu />

    <hr class="my-24 text-gray-600">

    <TheSiteMainMenu :site-slug="site" />

    <hr class="my-24 text-gray-600">

    <TheSiteManagementMenu />
  </div>
</template>

<style lang="scss">
#the-site-menu {
  .sidebar-nav:not(:last-child) {
    margin-bottom: 1rem;
  }
}
</style>
