<script setup lang="ts">
import TheSiteOverviewMetrics from '@/views/site/overview/analytics/parts/TheSiteOverviewMetrics.vue';

import TheSiteLighthouseReport from '@/views/site/overview/lighthouse/TheSiteLighthouseReport.vue';

defineOptions({
  name: 'TheSiteMetricsLighthouseReport',
});

defineProps<{
  site: string
}>();
</script>

<template>
  <div id="metrics-lighthouse-container" class="card mb-32">
    <div class="card-body">
      <TheSiteOverviewMetrics :date-range="7" :site="site" />

      <TheSiteLighthouseReport />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#metrics-lighthouse-container {
  min-height: 16rem;
}
</style>
