<script lang="ts" setup>
defineOptions({
  name: 'VSocialAuthLayout',
});
</script>

<template>
  <main>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </main>
</template>
