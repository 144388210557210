<script lang="ts" setup>
import type { LighthouseReport } from '@/types/models/site/service/performance/LighthouseReport';

import TheSiteLighthouseReportPerformanceTab
  from '@/views/site/overview/lighthouse/parts/TheSiteLighthouseReportPerformanceTab.vue';
import VSiteLighthouseReportPerformanceProgressCircular
  from '@/views/site/overview/lighthouse/parts/VSiteLighthouseReportPerformanceProgressCircular.vue';

defineOptions({
  name: 'TheSiteLighthouseReportPerformance',
});

defineProps<{
  report: LighthouseReport
}>();
</script>

<template>
  <MCollapsible v-slot="{ open }">
    <CollapsibleTrigger
      class="btn performance-section d-flex align-items-center justify-content-between w-100 collapsed text-dark border-0 bg-transparent px-0"
    >
      <span class="d-flex align-items-center">
        <span class="title-section d-flex flex-column align-items-start me-40">
          <span class="d-flex align-items-center mb-4">
            <span class="fw-semi me-8">{{ $t('site.overview.lighthouse.performance') }}</span>

            <v-icon
              v-tooltip="{
                content: $t('site.overview.lighthouse.tooltipInfo'),
                placement: 'bottom-start',
                arrowPadding: 24,
              }"
              class="text-gray-500"
              icon="circle-actions-alert-info"
            />
          </span>

          <span v-if="!!report?.updatedAt" class="text-xs text-gray-500">
            {{ $t('site.overview.lighthouse.updatedAt', { date: $str.formatDateTime(report.updatedAt) }) }}
          </span>
        </span>

        <span class="d-flex">
          <span class="d-flex align-items-center pe-24">
            <v-icon class="text-gray-500" icon="device-phone" size="xxl" space="me-8" />

            <VSiteLighthouseReportPerformanceProgressCircular :value="report.mobile.performance" valid-report />
          </span>

          <span class="d-flex align-items-center">
            <v-icon class="text-gray-500" icon="device-desktop" size="xxl" space="me-8" />

            <VSiteLighthouseReportPerformanceProgressCircular :value="report.desktop?.performance" valid-report />
          </span>
        </span>
      </span>

      <span class="position-relative pe-24 text-gray-600">
        <span>{{ $t('site.overview.lighthouse.viewDetails') }}</span>
        <v-icon :class="{ open }" class="chevron position-absolute top-50 end-0" icon="chevron-down" />
      </span>
    </CollapsibleTrigger>

    <CollapsibleContent>
      <ul id="nav-tab" class="nav nav-tabs mb-32 pt-16" role="tablist">
        <li class="nav-item me-32" role="presentation">
          <VButton
            id="nav-mobile-tab"
            aria-controls="nav-mobile"
            aria-selected="true"
            class="nav-link d-flex align-items-center lh-xl active mb-0 p-0 pb-12"
            data-bs-target="#nav-mobile"
            data-bs-toggle="tab"
            role="tab"
          >
            <v-icon icon="device-phone" size="xl" space="me-8" />
            <span>{{ $t('site.overview.lighthouse.mobile') }}</span>
          </VButton>
        </li>

        <li class="nav-item" role="presentation">
          <VButton
            id="nav-desktop-tab"
            aria-controls="nav-desktop"
            aria-selected="false"
            class="nav-link d-flex align-items-center lh-xl mb-0 p-0 pb-12"
            data-bs-target="#nav-desktop"
            data-bs-toggle="tab"
            role="tab"
          >
            <v-icon icon="device-desktop" size="xl" space="me-8" />
            <span>{{ $t('site.overview.lighthouse.desktop') }}</span>
          </VButton>
        </li>
      </ul>

      <div id="lighthouse-tabs" class="tab-content pb-32">
        <TheSiteLighthouseReportPerformanceTab
          v-if="!!report?.mobile?.metrics"
          id="nav-mobile"
          :items="report.mobile.metrics"
          default-active
          tab-type="mobile"
        />

        <TheSiteLighthouseReportPerformanceTab
          v-if="!!report?.desktop?.metrics"
          id="nav-desktop"
          :items="report.desktop.metrics"
          tab-type="desktop"
        />

        <div>
          <div class="my-16 text-xs text-gray-600">
            <span class="me-4">{{ $t('site.overview.lighthouse.estimateReport') }}</span>
            <a
              :href="report.reportLink"
              class="btn btn-link fw-medium text-decoration-none lh-lg p-0 align-baseline text-xs"
              rel="noopener"
              target="_blank"
            >
              {{ $t('site.overview.lighthouse.testNow') }}
            </a>
          </div>

          <div class="lighthouse-color-guide d-flex align-items-center justify-content-center text-sm">
            <span class="d-inline-block position-relative fail pe-24 ps-20">0-49</span>
            <span class="d-inline-block position-relative average pe-24 ps-20">50-89</span>
            <span class="d-inline-block position-relative pass ps-20">90-100</span>
          </div>
        </div>
      </div>
    </CollapsibleContent>
  </MCollapsible>
</template>

<style lang="scss" scoped>
.performance-section {
  .chevron {
    transform: translateY(-50%);
    transition: all 0.3s ease-out;

    &:not(.open) {
      transform: translateY(-50%);
    }

    &.open {
      transform: translateY(-50%) rotateX(180deg);
    }
  }
}
</style>
