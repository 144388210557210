<script setup lang="ts">
import TheMenuExtra from '@/components/global/menu/TheMenuExtra.vue';
import TheMenuMain from '@/components/global/menu/TheMenuMain.vue';
import TheMenuManagement from '@/components/global/menu/TheMenuManagement.vue';
import TheMenuTeams from '@/components/global/menu/TheMenuTeams.vue';

defineOptions({
  name: 'TheMenuDashboard',
});
</script>

<template>
  <div id="the-dashboard-menu">
    <TheMenuMain />

    <hr class="my-24 text-gray-600">

    <TheMenuTeams />

    <hr class="text-gray-600 my-24">

    <TheMenuManagement />

    <hr v-show="true" class="text-gray-600 mt-24 mb-12">

    <TheMenuExtra v-show="true" />
  </div>
</template>
