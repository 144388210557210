<script lang="ts" setup>
defineOptions({
  name: 'DashboardSitesPlaceholder',
});

withDefaults(
  defineProps<{
    feedType: 'grid' | 'list'
    numberOfItems?: number
    withoutWrapper?: boolean
  }>(),
  {
    feedType: 'grid',
    numberOfItems: 3,
    withoutWrapper: false,
  },
);
</script>

<template>
  <div v-if="!withoutWrapper" class="position-relative mb-48 mt-16">
    <!-- Card mode empty state -->
    <div v-if="feedType === 'grid'" class="items-grid">
      <div v-for="n in numberOfItems" :key="n" class="site-card placeholder-card position-relative pe-none">
        <div class="card-image position-relative w-100">
          <div class="site-image placeholder-wave">
            <span class="placeholder w-100" style="height: 130px; border-radius: 0.5rem 0.5rem 0 0" />
          </div>
        </div>

        <div class="d-flex flex-column placeholder-glow px-16 pb-20 pt-16">
          <span class="card-title d-flex align-items-center mb-4">
            <span class="placeholder" style="width: 100px" />
          </span>
          <p class="mb-4 text-xs">
            <span class="placeholder" style="width: 60px" />
          </p>
        </div>
      </div>
    </div>

    <!-- Table list mode empty state -->
    <div v-else class="table-responsive">
      <table class="sites-table table">
        <thead>
          <tr class="text-sm">
            <th class="placeholder-glow pe-0 ps-8">
              <span class="placeholder" style="width: 30px" />
            </th>
            <th class="placeholder-glow text-center">
              <span class="placeholder" style="width: 80px" />
            </th>
            <th class="placeholder-glow text-center">
              <span class="placeholder" style="width: 90px" />
            </th>
            <th class="placeholder-glow text-center">
              <span class="placeholder" style="width: 50px" />
            </th>
            <th class="fit placeholder-glow">
              <span class="placeholder" style="width: 55px" />
            </th>
            <th class="fit" />
          </tr>
        </thead>
        <tbody>
          <template v-for="n in numberOfItems" :key="n">
            <tr class="site-list-mode" style="height: 53px">
              <td v-for="m in 6" :key="m" :class="{ 'p-0': m === 0 }">
                <div class="d-flex justify-content-center placeholder-glow">
                  <span class="placeholder" style="width: 60px" />
                </div>
              </td>
            </tr>

            <tr class="bg-transparent">
              <td class="p-0" />
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>

  <template v-else-if="feedType === 'grid'">
    <div v-for="n in numberOfItems" :key="n" class="site-card placeholder-card position-relative pe-none">
      <div class="card-image position-relative w-100">
        <div class="site-image" :class="{ 'placeholder-wave': !withoutWrapper }">
          <span class="placeholder w-100" style="height: 130px; border-radius: 0.5rem 0.5rem 0 0" />
        </div>
      </div>

      <div class="d-flex flex-column placeholder-glow px-16 pb-20 pt-16">
        <span class="card-title d-flex align-items-center mb-4">
          <span class="placeholder" style="width: 100px" />
        </span>
        <p class="mb-4 text-xs">
          <span class="placeholder" style="width: 60px" />
        </p>
      </div>
    </div>
  </template>
  <template v-else>
    <template v-for="n in numberOfItems" :key="n">
      <tr class="site-list-mode" style="height: 53px">
        <td v-for="(m, index) in 6" :key="m" :class="{ 'p-0': m === 1 }">
          <div :class="{ 'placeholder-glow': !withoutWrapper }" class="d-flex justify-content-center">
            <span
              :style="{
                width: index === 5 || index === 4 ? '20px' : '60px',
              }"
              class="placeholder"
            />
          </div>
        </td>
      </tr>

      <tr class="bg-transparent">
        <td class="p-0" />
      </tr>
    </template>
  </template>
</template>
