<script lang="ts" setup>
import TheSiteOverview from '@/views/site/overview/TheSiteOverview.vue';

defineOptions({
  name: 'TheSite',
});

defineProps({
  site: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div id="the-site-overview" data-cy="site-overview-page">
    <Suspense>
      <KeepAlive>
        <TheSiteOverview :site="site">
          <template #sidebar>
            <slot name="sidebar" />
          </template>
        </TheSiteOverview>
      </KeepAlive>
    </Suspense>

    <router-view v-slot="{ Component, route }">
      <template v-if="route.name !== 'sites.site.manager.component.install'">
        <component :is="Component" />
      </template>
    </router-view>
  </div>
</template>
